// Marketing Preferences tab fields

import { Item as I } from '../../../../services/itemUtils';
import { FIELDS as F } from '../../../../services/consumerFields';
import * as C from '../../../../config/marketConfig';

export function getFields(L, market) {
    const channelsConfig = C.MV(C.MARKET_CONFIG_CHANNEL_OPTINS, market);

    const items1 = [
        I.new(L.consumerpage_marketing_field_opt_email, F.CHANNEL_OPT_EMAIL)
            .editable()
            .visibleIf(channelsConfig.email)
            .checkbox()
            .build(),
        I.new(L.consumerpage_marketing_field_opt_sms, F.CHANNEL_OPT_SMS)
            .editable()
            .visibleIf(channelsConfig.sms)
            .checkbox()
            .build(),
        I.new(L.consumerpage_marketing_field_opt_calls, F.CHANNEL_OPT_CALL)
            .editable()
            .visibleIf(channelsConfig.call)
            .checkbox()
            .build(),
        I.new(L.consumerpage_marketing_field_opt_dm, F.CHANNEL_OPT_DM)
            .editable()
            .visibleIf(channelsConfig.dm)
            .checkbox()
            .build(),
    ];

    const opts = C.getMarketValue(C.MARKET_CONFIG_BRAND_OPTINS, market);

    const items2 = opts.singleOptin
        ? [
              I.new(L.consumerpage_marketing_field_opt_brand, F.BRAND_OPT_FLAG)
                  .editable()
                  .checkbox()
                  .build(),
          ]
        : opts.brands.map((brand) =>
              I.new(brand, F.getBrandOptFlag(brand))
                  .editable()
                  .checkbox()
                  .build()
          );

    return [
        {
            id: 1,
            items: items1,
            label: L.consumerpage_marketing_section_channel,
        },
        { id: 2, items: items2, label: L.consumerpage_marketing_section_brand },
    ];
}
