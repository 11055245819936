// Polish translations

const messages = {
    // Navbar
    navbar_admin_portal: 'Admin Portal',
    navbar_consumer_search: 'Szukaj Klienta',
    navbar_language_choose_language: 'Wybierz język',
    navbar_market_choose_market: 'Wybierz market',
    navbar_logout: 'Wyloguj się',

    // Home page
    homepage_welcome: 'Witaj w Admin Portalu',
    homepage_not_allowed: 'Nie masz uprawnień do używania tej aplikacji.',
    homepage_search_btn: 'Szukaj Klienta',
    homepage_login_btn: 'Zaloguj się',
    homepage_logout_btn: 'Wyloguj się',

    // Search page
    searchpage_no_consumers_found: 'Nie znaleziono klientów.',
    searchpage_section_person: 'Osoba',
    searchpage_section_address: 'Adres',
    searchpage_btn_search: 'Szukaj',
    searchpage_btn_clear: 'Wyczyść',
    searchpage_field_uid: 'ID klienta',
    searchpage_field_first_name: 'Imię',
    searchpage_field_last_name: 'Nazwisko',
    searchpage_field_dob: 'Data urodzenia ({0})',
    searchpage_field_email: 'Adres email',
    searchpage_field_phone: 'Numer telefonu',
    searchpage_field_address: 'Adres',
    searchpage_field_city: 'Miasto',
    searchpage_field_postal: 'Kod pocztowy',
    searchpage_invalid_uid: 'Nieprawidłowe ID klienta.',
    searchpage_invalid_date: 'Nieprawidłowa data.',
    searchpage_invalid_email: 'Nieprawidłowy adres email.',
    searchpage_invalid_phone: 'Nieprawidłowy numer telefonu.',
    searchpage_invalid_wildcard:
        'Nieprawidłowe użycie znaku (*). Dozwolony jest pojedynczy znak (*), jeśli użyty samodzielnie.',
    searchpage_column_first_name: 'Imię',
    searchpage_column_last_name: 'Nazwisko',
    searchpage_column_address: 'Adres',
    searchpage_column_dob: 'Data urodzenia',
    searchpage_column_email: 'Email',
    searchpage_column_phone: 'Telefon',
    searchpage_column_actions: 'Akcje',
    searchpage_menu_view_profile: 'Pokaż Profil',
    searchpage_menu_age_verification: 'Weryfikacja Wieku',
    searchpage_menu_marketing_prefs: 'Zgody Marketingowe',
    searchpage_menu_password_reset: 'Reset Hasła',
    searchpage_menu_consumer_delete: 'Usunięcie Klienta',

    // Consumer page: tab names
    consumerpage_tab_system: 'System',
    consumerpage_tab_personal: 'Dane Osobowe',
    consumerpage_tab_address: 'Dane Adresowe',
    consumerpage_tab_marketing_prefs: 'Zgody Marketingowe',
    consumerpage_tab_age_verification: 'Weryfikacja Wieku',
    consumerpage_tab_loyalty_data: 'Programy Lojalnościowe',

    // Consumer page: action buttons
    consumerpage_action_label_disabled: 'Nieaktywny',
    consumerpage_action_label_deleted: 'Usunięty',
    consumerpage_action_label_locked: 'Zablokowany',
    consumerpage_action_nav_back: 'Wróć do Szukania',
    consumerpage_action_btn_edit: 'Edytuj',
    consumerpage_action_btn_save: 'Zapisz',
    consumerpage_action_btn_cancel: 'Anuluj',
    consumerpage_action_btn_reset_password: 'Resetuj Hasło',
    consumerpage_action_btn_block_consumer: 'Zablokuj Klienta',
    consumerpage_action_btn_unblock_consumer: 'Odblokuj Klienta',
    consumerpage_action_btn_delete_consumer: 'Usuń Klienta',
    consumerpage_action_btn_set_hav_status: 'Ustaw Status HAV',
    consumerpage_action_btn_assign_points: 'Przypisz punkty',

    // Consumer page: validation errors
    consumerpage_error_rejected_by_server: 'Wartość odrzucona przez serwer.',
    consumerpage_error_required: 'Pole wymagane.',
    consumerpage_error_too_long: 'Wartość za długa.',
    consumerpage_error_date: 'Nieprawidłowa data.',
    consumerpage_error_date_in_future: 'Data w przyszłości.',
    consumerpage_error_age_under_18: 'Uwaga: Wiek poniżej 18 lat.',
    consumerpage_error_email: 'Nieprawidłowy adres email.',
    consumerpage_error_phone: 'Nieprawidłowy numer telefonu.',
    consumerpage_error_country: 'Nieprawidłowy kraj.',
    consumerpage_error_province: 'Nieprawidłowa prowincja.',
    consumerpage_error_city: 'Nieprawidłowe miasto.',
    consumerpage_error_postal: 'Nieprawidłowy kod pocztowy.',

    // Consumer page: System tab
    consumerpage_system_section_blockings: 'Blokady',
    consumerpage_system_section_blockings_hist_data: 'Dane Historyczne',
    consumerpage_system_field_consumer_id: 'ID Klienta',
    consumerpage_system_field_created: 'Utworzony',
    consumerpage_system_field_registered: 'Zarejestrowany',
    consumerpage_system_field_registration_time: 'Data Rejestracji',
    consumerpage_system_field_registration_brand: 'Marka Rejestracji',
    consumerpage_system_field_registration_channel: 'Kanał Rejestracji',
    consumerpage_system_field_touchpoint_type: 'Typ Punktu Kontaktowego',
    consumerpage_system_field_touchpoint_id: 'ID Punktu Kontaktowego',
    consumerpage_system_field_account_locked: 'Konto Zablokowane',
    consumerpage_system_field_login_allowed: 'Logowanie Dozwolone',
    consumerpage_system_field_last_login: 'Ostatnie Logowanie',
    consumerpage_system_field_last_updated: 'Ostatnia Aktualizacja',
    consumerpage_system_field_last_updated_by: 'Ostatnia Aktualizacja Przez',
    consumerpage_system_field_blockedBy: 'Zablokowany Przez',
    consumerpage_system_field_blockedReason: 'Powód Blokady',
    consumerpage_system_field_blockedDate: 'Data Blokady',

    // Consumer page: Personal tab
    consumerpage_personal_field_first_name: 'Imię',
    consumerpage_personal_field_last_name: 'Nazwisko',
    consumerpage_personal_field_full_name: 'Imię i Nazwisko',
    consumerpage_personal_field_dob: 'Data Urodzenia',
    consumerpage_personal_field_gender: 'Płeć',
    consumerpage_personal_field_email: 'Adres Email',
    consumerpage_personal_field_language: 'Język',
    consumerpage_personal_field_home_country: 'Kraj Zamieszkania',
    consumerpage_personal_field_mobile_phone: 'Telefon Komórkowy',
    consumerpage_personal_field_home_phone: 'Telefon Domowy',
    consumerpage_personal_field_mobile_verification_status:
        'Status Weryfikacji Telefonu Komórkowego',
    consumerpage_personal_field_mobile_verification_date:
        'Data Weryfikacji Telefonu Komórkowego',

    // Consumer page: Address tab
    consumerpage_address_field_addr_type: 'Typ Adresu',
    consumerpage_address_field_country: 'Kraj',
    consumerpage_address_field_province: 'Prowincja',
    consumerpage_address_field_city: 'Miasto',
    consumerpage_address_field_addr_line_1: 'Adres Wiersz 1',
    consumerpage_address_field_addr_line_2: 'Adres Wiersz 2',
    consumerpage_address_field_postal: 'Kod Pocztowy',

    // Consumer page: Marketing Prefs tab
    consumerpage_marketing_section_channel: 'Zgody Kanałów',
    consumerpage_marketing_section_brand: 'Zgody Marek',
    consumerpage_marketing_section_brand_seg: 'Segmentacja Marek',
    consumerpage_marketing_section_brand_pref: 'Preferencje Marek',
    consumerpage_marketing_field_opt_email: 'Otrzymuj Wiadomości Email',
    consumerpage_marketing_field_opt_sms: 'Otrzymuj Wiadomości SMS',
    consumerpage_marketing_field_opt_calls: 'Otrzymuj Rozmowy Telefoniczne',
    consumerpage_marketing_field_opt_dm: 'Otrzymuj Pocztę',
    consumerpage_marketing_field_opt_brand: 'Otrzymuj Komunikację Marek',
    consumerpage_marketing_field_brand: 'Marka',
    consumerpage_marketing_field_subbrand: 'Marka Podrzędna',
    consumerpage_marketing_msg_choose_brand: 'Wybierz Markę',
    consumerpage_marketing_msg_choose_subbrand: 'Wybierz Markę Podrzędną',
    consumerpage_marketing_msg_invalid_brand: '<Nieprawidłowa Wartość>',
    consumerpage_marketing_msg_invalid_subbrand: '<Nieprawidłowa Wartość>',

    // Consumer page: Age Verification tab
    consumerpage_age_section_label_hav: 'Weryfikacja Wieku (HAV)',
    consumerpage_age_field_dob: 'Data Urodzenia',
    consumerpage_age_field_home_country: 'Kraj Zamieszkania',
    consumerpage_age_field_hav_last_updated: 'Ostatnia Aktualizacja',
    consumerpage_age_field_hav_country: 'Kraj',
    consumerpage_age_field_hav_type: 'Typ',
    consumerpage_age_field_hav_status: 'Status',

    // Consumer page: Loyalty Data tab
    consumerpage_loyalty_msg_consumer_not_registered:
        'Klient nie jest zapisany do programu lojalnościowego.',

    consumerpage_loyalty_field_current_points: 'Aktualna Liczba Punktów',
    consumerpage_loyalty_field_current_tier: 'Aktualny Poziom',
    consumerpage_loyalty_field_next_tier: 'Następny Poziom',
    consumerpage_loyalty_field_next_tier_points:
        'Punkty Potrzebne Do Następnego Poziomu',
    consumerpage_loyalty_field_brand: 'Wybierz markę',

    consumerpage_loyalty_label_needs_recalc: 'WYMAGA PRZELICZENIA',

    consumerpage_loyalty_btn_recalc: 'Przelicz',
    consumerpage_loyalty_btn_recalc_requested: 'Żądanie wysłano',

    consumerpage_loyalty_tab_transactions: 'Historia Transakcji',
    consumerpage_loyalty_tab_auditlogs: 'Audyt Log',

    consumerpage_loyalty_transaction_column_time: 'Czas Transakcji',
    consumerpage_loyalty_transaction_column_type: 'Typ',
    consumerpage_loyalty_transaction_column_details: 'Szczegóły',
    consumerpage_loyalty_transaction_column_points: 'Punkty',
    consumerpage_loyalty_transaction_column_remarks: 'Uwagi',
    consumerpage_loyalty_transaction_column_created_by: 'Utworzona Przez',

    consumerpage_loyalty_logs_column_time: 'Czas Logu',
    consumerpage_loyalty_logs_column_rule_status: 'Status Reguły',
    consumerpage_loyalty_logs_column_rule_type: 'Typ Reguły',
    consumerpage_loyalty_logs_column_rule_outcome: 'Wynik Reguły',
    consumerpage_loyalty_logs_column_event_type: 'Typ Zdarzenia',
    consumerpage_loyalty_logs_column_event_id: 'ID Zdarzenia',
    consumerpage_loyalty_logs_column_event_name: 'Nazwa Zdarzenia',
    consumerpage_loyalty_logs_column_corelation_id: 'ID Korelacji',

    // Dialogs
    dialog_btn_yes: 'Tak',
    dialog_btn_yes_confirm: 'Tak, potwierdzam',
    dialog_btn_block: 'Blokuj',
    dialog_btn_no: 'Nie',
    dialog_btn_cancel: 'Anuluj',
    dialog_get_consumer_error_title: 'Błąd pobierania danych klienta.',
    dialog_get_consumer_error_text: 'Czy chcesz ponowić?',
    dialog_password_reset_title: 'Reset hasła',
    dialog_password_reset_text: 'Czy na pewno kontynuować?',
    dialog_consumer_block_title: 'Blokowanie klienta',
    dialog_consumer_block_text: 'Wymagane dodatkowe informacje.',
    dialog_consumer_block_reason: 'Powód Blokady',
    dialog_consumer_unblock_title: 'Odblokowanie klienta',
    dialog_consumer_unblock_text: 'Czy na pewno kontynuować?',
    dialog_consumer_delete_title: 'Usunięcie klienta',
    dialog_consumer_delete_text: 'Czy na pewno kontynuować?',
    dialog_unsaved_changes_title: 'Niezapisane zmiany',
    dialog_unsaved_changes_text: 'Czy na pewno odrzucić zmiany?',
    dialog_save_changes_title: 'Zapisz zmiany',
    dialog_save_changes_text: 'Czy na pewno kontynuować?',
    dialog_last_name_change_title: 'Zmiana nazwy',
    dialog_last_name_change_text:
        'Zamierzasz zmienić nazwę klienta, czy potwierdzasz otrzymanie dokumentu tożsamości potwierdzającego tę zmianę?',
    dialog_set_hav_status_title: 'Ustawianie statusu HAV',
    dialog_set_hav_status_text: 'Czy na pewno kontynuować?',
    dialog_set_hav_status_confirm:
        'Zamierzasz ustawić status HAV klienta, czy potwierdzasz otrzymanie dokumentu tożsamości potwierdzającego tę zmianę?',

    // assign points dialog
    dialog_assign_points_title: 'Przypisz punkty',
    dialog_assign_points_subtitle:
        'Ręczne przypisanie punktów do konta klienta w przypadku sporu.',
    dialog_assign_points_input_point_label: 'Liczba Punktów',
    dialog_assign_points_input_remark_label: 'Powód',
    dialog_assign_points_cta_update_points: 'Przypisz punkty',
    dialog_assign_points_invalid_points: 'Nieprawidłowa liczba punktów.',

    // Notifications messages
    msg_consumer_updated: 'Klient zaktualizowany.',
    msg_consumer_update_failed: 'Błąd zapisu zmian.',
    msg_password_reset: 'Hasło zresetowane.',
    msg_password_reset_failed: 'Błąd resetu hasła.',
    msg_password_reset_not_available:
        'Reset hasła nie jest możliwy dla tego klienta.',
    msg_consumer_blocked: 'Klient zablokowany.',
    msg_consumer_block_failed: 'Błąd blokowania klienta.',
    msg_consumer_unblocked: 'Klient odblokowany.',
    msg_consumer_unblock_failed: 'Błąd odblokowania klienta.',
    msg_consumer_deleted: 'Klient usunięty.',
    msg_consumer_delete_failed: 'Błąd usuwania klienta.',
    msg_hav_status_updated: 'Status HAV ustawiony.',
    msg_hav_status_update_failed: 'Błąd ustawiania statusu HAV.',
    msg_loyalty_add_points_ok: 'Punkty przyznane.',
    msg_loyalty_add_points_error: 'Błąd podczas przyznawania punktów.',
    msg_loyalty_recalc_request_ok: 'Wysłano żądanie przeliczenia.',
    msg_loyalty_recalc_request_error: 'Błąd podczas żądania przeliczenia.',
    msg_brand_data_fetch_error: 'Błąd pobierania słownika marek.',
    msg_market_changed: 'Market zmieniony.',
    msg_logged_out: 'Wylogowano.',
    msg_session_lost: 'Utracono sesję, proszę zalogować się ponownie.',
    msg_session_expired: 'Sesja wygasła, proszę zalogować się ponownie.',

    // General errors
    err_error_fetching_data: 'Błąd pobierania danych.',
    err_refresh: 'Odśwież',
    err_retry: 'Ponów',

    // Dictionary: yes/no
    dict_yesno: {
        yes: 'Tak',
        no: 'Nie',
    },

    // Dictionary: gender
    dict_gender: {
        f: 'Kobieta',
        m: 'Mężczyzna',
        u: 'Nieokreślona',
    },

    // Dictionary: language
    dict_language: {
        en: 'Angielski',
        fr: 'Francuski',
        de: 'Niemiecki',
        es: 'Hiszpański',
        ph: 'Filipiński',
        id: 'Indonezyjski',
        ar: 'Arabski',
        ku: 'Kurdyjski',
        uk: 'Ukraiński',
    },

    // Dictionary: country
    dict_country: {
        EC: 'Ekwador',
        DE: 'Niemcy',
        CH: 'Szwajcaria',
        PH: 'Filipiny',
        ID: 'Indonezja',
        PS: 'Palestyna',
        IQ: 'Irak',
        AR: 'Argentyna',
        EG: 'Egipt',
        LB: 'Liban',
        UA: 'Ukraina',
        MA: 'Maroko',
    },

    // Dictionary: address type
    dict_address_type: {
        personal: 'Domowy',
    },

    // Dictionary: HAV status
    dict_hav_status: {
        VERIFIED: 'ZWERYFIKOWANY (VERIFIED)',
        FAILED: 'NIEPEŁNOLETNI (FAILED)',
        NOT_VERIFIED: 'NIEZWERYFIKOWANY (NOT_VERIFIED)',
        IN_PROGRESS: 'W TRAKCIE (IN_PROGRESS)',
        RESET: 'SKASOWANY (RESET)',
    },

    // Dictionary: last updated by
    dict_last_updated_by: {
        CONSUMER: 'KLIENT',
    },

    // Dictionary: verification status
    dict_verification_status: {
        VERIFIED: 'ZWERYFIKOWANY',
        'NOT VERIFIED': 'NIEZWERYFIKOWANY',
        INVALID: 'NIEPRAWIDŁOWY',
    },

    // Dictionary: blocking reason
    dict_blocking_reason: {
        FRAUDULENT: 'FRAUDULENT',
        DEACTIVATED: 'DEACTIVATED',
        FOR_DELETION: 'FOR DELETION',
        LAS_REQUEST: 'LAS REQUEST',
        NON_SMOKER: 'NON SMOKER',
        EMPLOYEE_TPA: 'EMPLOYEE / TPA',
    },

    // Dictionary: loyalty transaction remark
    dict_loyalty_transaction_remark: {
        POINTS_DELETED: 'PUNKTY USUNIĘTE',
        POINTS_NOT_REFLECTED: 'PUNKTY NIENALICZONE',
        FAILURE_OF_REDEMPTION: 'BŁĄD WYMIANY PUNKTÓW',
        OTHER_REASON: 'INNY POWÓD',
    },

    // Dictionary: loyalty transaction type
    dict_loyalty_transaction_type: {
        EARN: 'PRZYZNANIE',
        BURN: 'ZUŻYCIE',
    },
};

export default messages;
