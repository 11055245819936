// Personal tab fields

import { Item as I } from '../../../../services/itemUtils';
import { FIELDS as F } from '../../../../services/consumerFields';
import * as DM from '../../../../services/dataModel';
import * as C from '../../../../config/marketConfig';
import { formatAge } from '../../../../services/formatUtils';

export function getFields(L, market, ctx) {
    const firstNameLabel = C.MV(C.MARKET_LABELS.firstName, market, L);
    const firstName = C.MV(C.MARKET_FIELDS.firstName, market, ctx);
    const lastName = C.MV(C.MARKET_FIELDS.lastName, market, ctx);
    const dob = C.MV(C.MARKET_FIELDS.dob, market, ctx);
    const gender = C.MV(C.MARKET_FIELDS.gender, market);
    const email = C.MV(C.MARKET_FIELDS.email, market);
    const language = C.MV(C.MARKET_FIELDS.language, market);
    const mobilePhone = C.MV(C.MARKET_FIELDS.mobilePhone, market);

    const items1 = [
        I.new(firstNameLabel, F.FIRST_NAME)
            .visibleIf(firstName.visible)
            .editableIf(firstName.editable)
            .requiredIf(firstName.required, L.consumerpage_error_required)
            .build(),
        I.new(L.consumerpage_personal_field_last_name, F.LAST_NAME)
            .visibleIf(lastName.visible)
            .editableIf(lastName.editable)
            .requiredIf(lastName.required, L.consumerpage_error_required)
            .build(),
        I.new(L.consumerpage_personal_field_dob, F.DATE_OF_BIRTH)
            .visibleIf(dob.visible)
            .editableIf(dob.editable)
            .requiredIf(dob.required, L.consumerpage_error_required)
            .date(C.getMarketValue(C.MARKET_FORMAT_DATE, market))
            .normalizeValidator(L.consumerpage_error_date)
            .validator((_, valueN) => {
                const isFuture = valueN && new Date(valueN) > new Date();
                if (isFuture) return L.consumerpage_error_date_in_future;
            })
            .hinter((_, valueN) => {
                const age = formatAge(valueN);
                if (age != null && age < 18)
                    return L.consumerpage_error_age_under_18;
            })
            .build(),
        I.new(L.consumerpage_personal_field_gender, F.GENDER)
            .editableIf(gender.editable)
            .requiredIf(gender.required, L.consumerpage_error_required)
            .dropdown(L.dict_gender)
            .build(),
        I.new(L.consumerpage_personal_field_email, F.EMAIL)
            .editableIf(email.editable)
            .requiredIf(email.required, L.consumerpage_error_required)
            .regexpValidator(
                C.getMarketValue(C.MARKET_REGEXP_EMAIL, market),
                L.consumerpage_error_email
            )
            .labelsProvider((model) => {
                const status = DM.getValue(model, F.EMAIL_VERIFICATION_STATUS);
                const label = L.dict_verification_status[status];
                return [{ label }];
            })
            .build(),
        I.new(L.consumerpage_personal_field_language, F.LANGUAGES)
            .editableIf(language.editable)
            .requiredIf(language.required, L.consumerpage_error_required)
            .dropdown(L.dict_language, C.MARKET_VALUES_LANGUAGE[market])
            .build(),
        I.new(L.consumerpage_personal_field_home_country, F.COUNTRY_CODE)
            .dropdown(L.dict_country)
            .build(),
    ];

    const items2 = [
        I.new(L.consumerpage_personal_field_mobile_phone, F.MOBILE_NUMBER)
            .editableIf(mobilePhone.editable)
            .requiredIf(mobilePhone.required, L.consumerpage_error_required)
            .regexpValidator(
                C.getMarketValue(C.MARKET_REGEXP_MOBILE, market),
                L.consumerpage_error_phone
            )
            .labelsProvider((model) => {
                const status = DM.getValue(model, F.MOBILE_VERIFICATION_STATUS);
                const label = L.dict_verification_status[status];
                return [{ label }];
            })
            .build(),
        I.new(L.consumerpage_personal_field_home_phone, F.HOME_NUMBER).build(),
    ];

    return [
        { id: 1, items: items1 },
        { id: 2, items: items2 },
    ];
}
