// Spanish translations

const messages = {
    // Navbar
    navbar_admin_portal: 'Admin Portal',
    navbar_consumer_search: 'Busqueda de Cliente',
    navbar_language_choose_language: 'Selecciona idioma',
    navbar_market_choose_market: 'Selecciona mercado',
    navbar_logout: 'Logout',

    // Home page
    homepage_welcome: 'Bienvenido al Admin Portal',
    homepage_not_allowed: 'No tienes accesos para usar esta aplicación.',
    homepage_search_btn: 'Busqueda de Cliente',
    homepage_login_btn: 'Login',
    homepage_logout_btn: 'Logout',

    // Search page
    searchpage_no_consumers_found: 'Cliente no encontrado.',
    searchpage_section_person: 'Persona',
    searchpage_section_address: 'Direccion',
    searchpage_btn_search: 'Busqueda',
    searchpage_btn_clear: 'Borrar',
    searchpage_field_uid: 'ID Cliente',
    searchpage_field_first_name: 'Nombre',
    searchpage_field_last_name: 'Apellido',
    searchpage_field_dob: 'Fecha de Nacimiento ({0})',
    searchpage_field_email: 'Dirección de Email',
    searchpage_field_phone: 'Número de Teléfono',
    searchpage_field_address: 'Direccion',
    searchpage_field_city: 'Ciudad',
    searchpage_field_postal: 'Código Postal',
    searchpage_invalid_uid: 'ID de cliente inválido.',
    searchpage_invalid_date: 'Fecha inválida.',
    searchpage_invalid_email: 'Email inválido.',
    searchpage_invalid_phone: 'Numero de telefono inválido.',
    searchpage_invalid_wildcard:
        'Uso de comodín (*) no válido. Se permite un comodín único cuando se usa solo.',
    searchpage_column_first_name: 'Nombre',
    searchpage_column_last_name: 'Apellido',
    searchpage_column_address: 'Dirección',
    searchpage_column_dob: 'Fecha de Nacimiento',
    searchpage_column_email: 'Email',
    searchpage_column_phone: 'Número de Teléfono',
    searchpage_column_actions: 'Acciones',
    searchpage_menu_view_profile: 'Ver Perfil',
    searchpage_menu_age_verification: 'Verificación de Edad',
    searchpage_menu_marketing_prefs: 'Preferencias de Marketing',
    searchpage_menu_password_reset: 'Reseteo de Contraseña',
    searchpage_menu_consumer_delete: 'Eliminar Cliente',

    // Consumer page: tab names
    consumerpage_tab_system: 'Sistema',
    consumerpage_tab_personal: 'Personal',
    consumerpage_tab_address: 'Datos de Direccion',
    consumerpage_tab_marketing_prefs: 'Preferencias de Marketing',
    consumerpage_tab_age_verification: 'Verificación de Edad',
    consumerpage_tab_loyalty_data: 'Loyalty Programs',

    // Consumer page: action buttons
    consumerpage_action_label_disabled: 'Desactivado',
    consumerpage_action_label_deleted: 'Eliminado',
    consumerpage_action_label_locked: 'Bloqueado',
    consumerpage_action_nav_back: 'Volver a la búsqueda',
    consumerpage_action_btn_edit: 'Editar',
    consumerpage_action_btn_save: 'Guardar',
    consumerpage_action_btn_cancel: 'Cancelar',
    consumerpage_action_btn_reset_password: 'Reseteo de Contraseña',
    consumerpage_action_btn_block_consumer: 'Bloque Cliente',
    consumerpage_action_btn_unblock_consumer: 'Desbloquear Cliente',
    consumerpage_action_btn_delete_consumer: 'Eliminar Cliente',
    consumerpage_action_btn_set_hav_status: 'Establecer estado HAV',
    consumerpage_action_btn_assign_points: 'Assign Points',

    // Consumer page: validation errors
    consumerpage_error_rejected_by_server: 'Valor rechazado por servidor.',
    consumerpage_error_required: 'Campo requerido.',
    consumerpage_error_too_long: 'Valor demasiado largo.',
    consumerpage_error_date: 'Fecha inválida.',
    consumerpage_error_date_in_future: 'Fecha en el futuro.',
    consumerpage_error_age_under_18: 'Nota: Edad menor de 18 años.',
    consumerpage_error_email: 'Mail inválido.',
    consumerpage_error_phone: 'Número de Teléfono inválido.',
    consumerpage_error_country: 'País inválido.',
    consumerpage_error_province: 'Provincia inválida.',
    consumerpage_error_city: 'Ciudad inválida.',
    consumerpage_error_postal: 'Código Postal inválido.',

    // Consumer page: System tab
    consumerpage_system_section_blockings: 'Bloqueos',
    consumerpage_system_section_blockings_hist_data: 'Datos Históricos',
    consumerpage_system_field_consumer_id: 'ID Cliente',
    consumerpage_system_field_created: 'Creado',
    consumerpage_system_field_registered: 'Registrado',
    consumerpage_system_field_registration_time: 'Fecha de Registración',
    consumerpage_system_field_registration_brand: 'Marca de Registro',
    consumerpage_system_field_registration_channel: 'Canal de Registro',
    consumerpage_system_field_touchpoint_type: 'Tipo de Punto de Contacto',
    consumerpage_system_field_touchpoint_id: 'ID de Punto de Contacto',
    consumerpage_system_field_account_locked: 'Cuenta Bloqueada',
    consumerpage_system_field_login_allowed: 'Login Permitido',
    consumerpage_system_field_last_login: 'Ultimo Login',
    consumerpage_system_field_last_updated: 'Ultima Actualización',
    consumerpage_system_field_last_updated_by: 'Actualizado Por',
    consumerpage_system_field_blockedBy: 'Bloqueado Por',
    consumerpage_system_field_blockedReason: 'Motivo del Bloqueo',
    consumerpage_system_field_blockedDate: 'Fecha Bloqueada',

    // Consumer page: Personal tab
    consumerpage_personal_field_first_name: 'Nombre',
    consumerpage_personal_field_last_name: 'Apellido',
    consumerpage_personal_field_full_name: 'Nombre',
    consumerpage_personal_field_dob: 'Fecha de Nacimiento',
    consumerpage_personal_field_gender: 'Género',
    consumerpage_personal_field_email: 'Email',
    consumerpage_personal_field_language: 'Idioma',
    consumerpage_personal_field_home_country: 'País Origen',
    consumerpage_personal_field_mobile_phone: 'Teléfono Mobil',
    consumerpage_personal_field_home_phone: 'Teléfono Personal',
    consumerpage_personal_field_mobile_verification_status:
        'Estado de Verificacion Numero Mobil',
    consumerpage_personal_field_mobile_verification_date:
        'Fecha de Verificacion Numero Mobil',

    // Consumer page: Address tab
    consumerpage_address_field_addr_type: 'Tipo de Direccion',
    consumerpage_address_field_country: 'País',
    consumerpage_address_field_province: 'Provincia',
    consumerpage_address_field_city: 'Ciudad',
    consumerpage_address_field_addr_line_1: 'Direccion 1',
    consumerpage_address_field_addr_line_2: 'Direccion 2',
    consumerpage_address_field_postal: 'Codigo Postal',

    // Consumer page: Marketing Prefs tab
    consumerpage_marketing_section_channel: 'Opt-ins por Canal',
    consumerpage_marketing_section_brand: 'Opt-ins por Marca',
    consumerpage_marketing_section_brand_seg: 'Segmentacion de Marca',
    consumerpage_marketing_section_brand_pref: 'Preferencias de Marca',
    consumerpage_marketing_field_opt_email: 'Recibe Email',
    consumerpage_marketing_field_opt_sms: 'Recibe SMS',
    consumerpage_marketing_field_opt_calls: 'Recibe Llamadas',
    consumerpage_marketing_field_opt_dm: 'Recibe Envio Postales',
    consumerpage_marketing_field_opt_brand: 'Recibe Comunicación de Marca',
    consumerpage_marketing_field_brand: 'Marca',
    consumerpage_marketing_field_subbrand: 'Sub Categoria',
    consumerpage_marketing_msg_choose_brand: 'Seleccione Marca',
    consumerpage_marketing_msg_choose_subbrand: 'Seleccione Sub Categoria',
    consumerpage_marketing_msg_invalid_brand: '<Marca invalidad>',
    consumerpage_marketing_msg_invalid_subbrand: '<Sub Categoria invalidad>',

    // Consumer page: Age Verification tab
    consumerpage_age_section_label_hav: 'Verificación de Edad (HAV)',
    consumerpage_age_field_dob: 'Fecha de Nacimiento',
    consumerpage_age_field_home_country: 'País Origen',
    consumerpage_age_field_hav_last_updated: 'Ultima Actualización',
    consumerpage_age_field_hav_country: 'País',
    consumerpage_age_field_hav_type: 'Tipo',
    consumerpage_age_field_hav_status: 'Estado',

    // Consumer page: Loyalty Data tab
    consumerpage_loyalty_msg_consumer_not_registered:
        'Consumer is not enrolled in loyalty program.',

    consumerpage_loyalty_field_current_points: 'Puntos totales actuales',
    consumerpage_loyalty_field_current_tier: 'Nivel actual',
    consumerpage_loyalty_field_next_tier: 'Próximo nivel',
    consumerpage_loyalty_field_next_tier_points: 'Puntos necesarios',
    consumerpage_loyalty_field_brand: 'Seleccionar marca',

    consumerpage_loyalty_label_needs_recalc: 'NEEDS RECALCULATION',

    consumerpage_loyalty_btn_recalc: 'Recalculate',
    consumerpage_loyalty_btn_recalc_requested: 'Requested',

    consumerpage_loyalty_tab_transactions: 'Historial de transacciones',
    consumerpage_loyalty_tab_auditlogs: 'Audit Log',

    consumerpage_loyalty_transaction_column_time: 'Transaction Time',
    consumerpage_loyalty_transaction_column_type: 'Type',
    consumerpage_loyalty_transaction_column_details: 'Details',
    consumerpage_loyalty_transaction_column_points: 'Points',
    consumerpage_loyalty_transaction_column_remarks: 'Remarks',
    consumerpage_loyalty_transaction_column_created_by: 'Created By',

    consumerpage_loyalty_logs_column_time: 'Log Time',
    consumerpage_loyalty_logs_column_rule_status: 'Rule Status',
    consumerpage_loyalty_logs_column_rule_type: 'Rule Type',
    consumerpage_loyalty_logs_column_rule_outcome: 'Rule Outcome',
    consumerpage_loyalty_logs_column_event_type: 'Event Type',
    consumerpage_loyalty_logs_column_event_id: 'Event ID',
    consumerpage_loyalty_logs_column_event_name: 'Event Name',
    consumerpage_loyalty_logs_column_corelation_id: 'Correlation ID',

    // Dialogs
    dialog_btn_yes: 'Si',
    dialog_btn_yes_confirm: 'Si, confirmo',
    dialog_btn_block: 'Bloquear',
    dialog_btn_no: 'No',
    dialog_btn_cancel: 'Cancelar',
    dialog_get_consumer_error_title: 'Error al conseguir informacion.',
    dialog_get_consumer_error_text: 'Desea reintentar?',
    dialog_password_reset_title: 'Reseteo de contraseña',
    dialog_password_reset_text: 'Desea continuar?',
    dialog_consumer_block_title: 'Bloque de clientes',
    dialog_consumer_block_text: 'Información adicional necesaria.',
    dialog_consumer_block_reason: 'Motivo del Bloqueo',
    dialog_consumer_unblock_title: 'Desbloqueo de clientes',
    dialog_consumer_unblock_text: 'Desea continuar?',
    dialog_consumer_delete_title: 'Eliminación de clientes',
    dialog_consumer_delete_text: 'Desea continuar?',
    dialog_unsaved_changes_title: 'Cambios no guardados',
    dialog_unsaved_changes_text: 'Desea deshacer los cambios?',
    dialog_save_changes_title: 'Guardar datos',
    dialog_save_changes_text: 'Desea continuar?',
    dialog_last_name_change_title: 'Cambios en Apellido',
    dialog_last_name_change_text:
        'Se encuentra por cambiar el nombre del LAS, confirma que el/la persona ha enviado un documento para autorizar el cambio?',
    dialog_set_hav_status_title: 'Modificacion estado validacion de edad',
    dialog_set_hav_status_text: 'Desea continuar?',
    dialog_set_hav_status_confirm:
        'Está a punto de establecer el estado HAV de este LAS, confirma que el/la persona ha enviado un documento para autorizar el cambio?',

    // assign points dialog
    dialog_assign_points_title: 'Asignar puntos manualmente',
    dialog_assign_points_subtitle:
        'Asignar puntos manualmente a la cuenta del consumidor en caso de disputa.',
    dialog_assign_points_input_point_label: 'Ingrese la cantidad de puntos',
    dialog_assign_points_input_remark_label: 'Comentario',
    dialog_assign_points_cta_update_points: 'Actualizar puntos',
    dialog_assign_points_invalid_points: 'Invalid number of points.',

    // Notifications messages
    msg_consumer_updated: 'Cliente actualizado.',
    msg_consumer_update_failed: 'Actualizacion de cliente fallida.',
    msg_password_reset: 'Reseteo de contraseña satisfactoria.',
    msg_password_reset_failed: 'Reseteo de contraseña fallida.',
    msg_password_reset_not_available:
        'El restablecimiento de contraseña no está disponible para este consumidor.',
    msg_consumer_blocked: 'Cliente bloqueado.',
    msg_consumer_block_failed: 'Bloqueo de cliente fallido.',
    msg_consumer_unblocked: 'Cliente desbloqueado.',
    msg_consumer_unblock_failed: 'Error en el desbloqueo del cliente.',
    msg_consumer_deleted: 'Cliente eliminado.',
    msg_consumer_delete_failed: 'Eliminacion cliente fallida.',
    msg_hav_status_updated: 'Estado de verificacion de edad actualizado.',
    msg_hav_status_update_failed: 'Estado de verificacion de edad fallido.',
    msg_loyalty_add_points_ok: 'Loyalty points added.',
    msg_loyalty_add_points_error: 'Error while adding loyalty points.',
    msg_loyalty_recalc_request_ok: 'Recalculation requested.',
    msg_loyalty_recalc_request_error: 'Error requesting recalculation.',
    msg_brand_data_fetch_error: 'Error al recuperar datos de marca.',
    msg_market_changed: 'Mercado cambió.',
    msg_logged_out: 'Desconectado.',
    msg_session_lost: 'Sesión perdida, por favor conéctese de nuevo.',
    msg_session_expired: 'Sesión expiró, por favor conéctese de nuevo.',

    // General errors
    err_error_fetching_data: 'Error fetching data.',
    err_refresh: 'Refresh',
    err_retry: 'Retry',

    // Dictionary: yes/no
    dict_yesno: {
        yes: 'Si',
        no: 'No',
    },

    // Dictionary: gender
    dict_gender: {
        m: 'Masculino',
        f: 'Femenino',
        u: 'No especificado',
    },

    // Dictionary: language
    dict_language: {
        en: 'Ingles',
        fr: 'Frances',
        de: 'Aleman',
        es: 'Español',
        ph: 'Filipino',
        id: 'Indonesio',
        ar: 'Árabe',
        ku: 'Kurdo',
        uk: 'Ukraniano',
    },

    // Dictionary: country
    dict_country: {
        EC: 'Ecuador',
        DE: 'Alemania',
        CH: 'Suiza',
        PH: 'Filipinas',
        ID: 'Indonesia',
        PS: 'Palestina',
        IQ: 'Iraq',
        AR: 'Argentina',
        EG: 'Egipto',
        LB: 'Líbano',
        UA: 'Ucrania',
        MA: 'Marruecos',
    },

    // Dictionary: address type
    dict_address_type: {
        personal: 'Personal',
    },

    // Dictionary: HAV status
    dict_hav_status: {
        VERIFIED: 'VERIFICADO (VERIFIED)',
        FAILED: 'FALLÓ (FAILED)',
        NOT_VERIFIED: 'NO VERIFICADO (NOT_VERIFIED)',
        IN_PROGRESS: 'EN PROGRESO (IN_PROGRESS)',
        RESET: 'RESETAR (RESET)',
    },

    // Dictionary: last updated by
    dict_last_updated_by: {
        CONSUMER: 'CLIENTE',
    },

    // Dictionary: verification status
    dict_verification_status: {
        VERIFIED: 'VERIFICADO',
        'NOT VERIFIED': 'NO VERIFICADO',
        INVALID: 'INVALIDO',
    },

    // Dictionary: blocking reason
    dict_blocking_reason: {
        FRAUDULENT: 'FRAUDULENTO',
        DEACTIVATED: 'DESACTIVADO',
        FOR_DELETION: 'PARA ELIMINACIÓN',
        LAS_REQUEST: 'SOLICITUD DE CLIENTE',
        NON_SMOKER: 'NO FUMADOR',
        EMPLOYEE_TPA: 'EMPLEADO / TPA',
    },

    // Dictionary: loyalty transaction remark
    dict_loyalty_transaction_remark: {
        POINTS_DELETED: 'POINTS DELETED',
        POINTS_NOT_REFLECTED: 'POINTS NOT REFLECTED',
        FAILURE_OF_REDEMPTION: 'FAILURE OF REDEMPTION',
        OTHER_REASON: 'OTHER REASON',
    },

    // Dictionary: loyalty transaction type
    dict_loyalty_transaction_type: {
        EARN: 'EARN',
        BURN: 'BURN',
    },
};

export default messages;
