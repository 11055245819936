// Edit Mode hook

import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { dialogShow, dialogClose } from '../../../store/actions/dialogActions';
import {
    executeConsumerUpdate,
    profileChange,
    profileEditMode,
    profileValidateRequired,
} from '../../../store/actions/profileActions';
import ActionButton from './ActionButton';
import { FIELDS } from '../../../services/consumerFields';
import { useMessages } from '../../../hooks/useMessages';
import * as DM from '../../../services/dataModel';
import { MARKET_CONFIRMATIONS, MV } from '../../../config/marketConfig';

export function useEditMode(profile, sections = null) {
    const { user } = useSelector((state) => state.auth);
    const { market, hasConsumerUpdate } = user || {};

    const dispatch = useDispatch();
    const { id } = useParams();
    const { L } = useMessages();

    const { editMode } = profile;
    const dataChanged = DM.hasChanges(profile);
    const dataValid = !DM.hasErrors(profile);

    const canEdit = hasConsumerUpdate;
    const canSave = hasConsumerUpdate && dataChanged && dataValid;

    const handleEdit = useCallback(() => {
        dispatch(profileEditMode(true));
        dispatch(profileValidateRequired(sections));
    }, [dispatch, sections]);

    const handleSave = useCallback(() => {
        const confirmNameChange = MV(MARKET_CONFIRMATIONS.nameChange, market);

        const doSave = () => {
            dispatch(executeConsumerUpdate(id, profile));
        };

        dispatch(
            dialogShow(
                L.dialog_save_changes_title,
                L.dialog_save_changes_text,
                () => {
                    const fnChanged = DM.isChanged(profile, FIELDS.FIRST_NAME);
                    const lnChanged = DM.isChanged(profile, FIELDS.LAST_NAME);
                    const nameChanged = fnChanged || lnChanged;

                    if (nameChanged && confirmNameChange) {
                        dispatch(
                            dialogShow(
                                L.dialog_last_name_change_title,
                                L.dialog_last_name_change_text,
                                doSave,
                                null,
                                {
                                    confirmText: L.dialog_btn_yes_confirm,
                                    declineText: L.dialog_btn_cancel,
                                }
                            )
                        );
                    } else {
                        doSave();
                    }
                }
            )
        );
    }, [dispatch, L, id, profile, market]);

    const handleCancel = useCallback(() => {
        if (!dataChanged) {
            dispatch(profileEditMode(false));
            return;
        }
        dispatch(
            dialogShow(
                L.dialog_unsaved_changes_title,
                L.dialog_unsaved_changes_text,
                () => {
                    dispatch(dialogClose());
                    dispatch(profileEditMode(false));
                }
            )
        );
    }, [dispatch, dataChanged, L]);

    const handleChange = useCallback(
        (itemValue) => dispatch(profileChange(itemValue)),
        [dispatch]
    );

    const renderEditButtons = useCallback(() => {
        return (
            <>
                {editMode && (
                    <>
                        <ActionButton disabled={!canSave} onClick={handleSave}>
                            {L.consumerpage_action_btn_save}
                        </ActionButton>
                        <ActionButton variant="outlined" onClick={handleCancel}>
                            {L.consumerpage_action_btn_cancel}
                        </ActionButton>
                    </>
                )}
                {!editMode && (
                    <ActionButton disabled={!canEdit} onClick={handleEdit}>
                        {L.consumerpage_action_btn_edit}
                    </ActionButton>
                )}
            </>
        );
    }, [handleSave, handleCancel, handleEdit, editMode, canSave, canEdit, L]);

    return useMemo(
        () => ({
            canEdit,
            canSave,
            handleChange,
            renderEditButtons,
        }),
        [canEdit, canSave, handleChange, renderEditButtons]
    );
}
